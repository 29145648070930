import React from 'react'

import "./error.css"

export default function Error() {
  return (
    <div className='mc__error'>
        <h1>ERROR: PAGE DOES NOT EXIST</h1>
        <i>Img credit: Chaotic Short</i>
    </div>
  )
}


import React from 'react'
import { Navbar, Footer } from "../../components"
import { How } from "../Play"

export default function Play() {
  return (
    <div className='mc_play'>
      <Navbar />
      <How />
      <Footer />
    </div>
  )
}

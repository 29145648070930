import React from 'react'
import './footer.css'

export default function Footer() {
  return (
    <div className='mc__footer'>
      <div className='mc__footer-container'>
        <div className='mc__footer-container_follow'>
          <h2>Follow Us</h2>
          <a href='https://www.tiktok.com/@mythoscraft?lang=en' target="_blank" rel="noreferrer">Tiktok</a>
          <a href='https://www.instagram.com/mythos_mc/?hl=en' target="_blank" rel="noreferrer">Instagram</a>
          <a href="https://www.reddit.com/r/MythosCraftServer/" target="_blank" rel="noreferrer">Reddit</a>
          <a href='https://discord.com/invite/FgurDkv74K' target="_blank" rel="noreferrer">Discord</a>
        </div>
        <div className='mc__footer-container_links'>
          <h2>Links</h2>
          <a href="https://mythoscraftmc.fandom.com/wiki/MythosCraft_Wiki" target="_blank" rel="noreferrer">Our Wiki</a>
          <a href="https://store.mythosmc.com" target="_blank" rel="noreferrer">The Store</a>
        </div>
        <div className='mc__footer-container_pages'>
          <h2>Pages</h2>
          <a href='../play'>How to Play</a>
          <a href='../apply'>Join the Team</a>
          <a href='../community'>Community News</a>
        </div>
        <div className='mc__footer-container_login'>
          <h2>Account</h2>
          <a href='http://mythosmc.com'>Admin Login</a>
        </div>
      </div>
  </div>
  )
}

import React from 'react'
import { Navbar, Footer } from "../../components"
import { TeamPage } from '../Apply'

export default function Apply() {
  return (
    <div className='mc__apply'>
      <Navbar />
      <TeamPage />
      <Footer />
    </div>
  )
}

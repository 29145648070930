import React from 'react'
import { useNavigate } from 'react-router-dom'
import olympus from "../../../assets/images/hades.png"
import "./play.css"
 
export default function Play() {
  const navigate = useNavigate()
  return (
    <div className='mc__play'>
      <div className='mc__play-container'>
        <div className='mc__play-container_img'>
          <img src={olympus} alt='olympus'/>
        </div>
        <div className='mc__play-container_content'>
          <h1>Play as children of the Greek Gods and Goddesses!</h1>
          <p>MythosCraft is a greek mythology inspired minecraft server multiplayer experience where you play as the children of the gods and gain cool abilities to add an extra layer to the game!</p>
          <button onClick={()=>navigate("/play")}>How To Play?</button>
        </div>
      </div>
    </div>
  )
}

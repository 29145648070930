import React, { useState, useEffect} from 'react'
import "./header.css"
import { RiFileCopyLine, RiIndeterminateCircleFill } from 'react-icons/ri';
import copy from "copy-to-clipboard"; 

export default function Header() {
  const copyToClipboard = () => {
    copy("play.mythosmc.com");
    alert(`You have copied the ip: play.mythosmc.com`);
  }

  function Online() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('https://mcapi.us/server/status?ip=play.mythosmc.com');
          const data = await response.json();
          setData(data);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      };
      fetchData();
    }, []);
  
    if (loading) {
      return <p>Loading...</p>;
    }
    if (error) {
      return <p>An error occurred: {error.message}</p>;
    }
    if (data) {
      return (
        <>
          {data.online ? <div><p>Server Status: Online</p><RiIndeterminateCircleFill color={"#58cc02"}/></div> : <div><p>Status: Offline</p><RiIndeterminateCircleFill color={"red"}/></div>}
        </>
      );
    }
    return null;
  }


  return (
    <div className='mc__header'>
        <div className='mc__header-container'>
            <h1>Welcome to MythosCraft</h1>
            <p>The oldest Greek Mythology based Minecraft Server</p>
            <div className='mc__header-container_button'>
              <button onClick={copyToClipboard}><p>PLAY.MYTHOSMC.COM</p><RiFileCopyLine color='white' /></button>
            </div>
            <div className='mc__header-container_online'>
              <Online />
            </div>
        </div>
    </div>
  )
}


import React from 'react'
import { Navbar, Footer } from "../../components"
import { Error } from "../ErrorPage"

export default function ErrorPage() {
  return (
    <div className='mc__errorpage'>
      <Navbar />
      <Error />
      <Footer />
    </div>
  )
}

import React, {useState, useEffect} from 'react'
import CommVideo from "../commVideo/CommVideo"
import "./commcontent.css"

export default function CommContent() {
    const [currentChannelId, setCurrentChannelId] = useState("UC0rmrqNoH1ENhOKedKdxSLA");
    const [videos, setVideos] = useState([]);
  
    const baseUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D';
  
    useEffect(() => {
        (async () => {
            if (currentChannelId) {
                try {
                    const data = await fetch(`${baseUrl}${currentChannelId}`).then(response => response.json());
                    setVideos(data.items);
                } catch (error) {
                    console.log(error);
                }
            }
        })();
    }, [currentChannelId]);
  
    const vids = videos.slice(0,4)
    
    const showVids = vids.map(video => <CommVideo key={video.guid} video={video} />)
    return (
      <div className='mc__commcontent' id='videos'>
          <div className='mc__commcontent-container'>
            <div className='mc__commcontent-container_heading'>
              <h1>Recent Videos From MythosCraft</h1>
            </div>
            <div className="mc__commcontent-container_videos">
                  {showVids}
            </div>
          </div>
  
      </div>
    )
}

import React from 'react'
import "./how.css"

export default function How() {
  return (
    <div className='mc__how'>
      <div className='mc__how-container'>
        <h1>How To Join the MythosCraft Server!</h1>
        <p>To join the MythosCraft Minecraft server, you will need to have a Minecraft account and the game installed on your computer.</p>
        <div className='mc__how-container_notice'>
          <p>Notice: MythosCraft is currently only available on the Java Edition of the game, bedrock or other editions are currently in the works!</p>
        </div>
        <p>Once you have these, you can follow these steps to join the server:</p>
        <ol>
          <li>Launch the Minecraft game on your computer.</li>
          <li>In the main menu, click on the "Multiplayer" option.</li>
          <li>Click on the "Add Server" button.</li>
          <li>In the "Server Name" field, enter "MythosCraft".</li>
          <li>In the "Server Address" field, enter "play.mythosmc.com".</li>
          <li>Click on the "Done" button.</li>
          <li>The MythosCraft server should now appear in the list of servers. Select it and click on the "Join Server" button to join the server and start playing.</li>
        </ol>
        <p>Alternatively, you can also use the "Direct Connect" feature to join the server. To do this, follow these steps:</p>
        <ol>
          <li>In the Minecraft main menu, click on the "Multiplayer" option.</li>
          <li>Click on the "Direct Connect" button.</li>
          <li>In the "Server Address" field, enter "play.mythosmc.com".</li>
          <li>Click on the "Join Server" button to connect to the server and start playing.</li>
        </ol>
        <div className='mc__how-container_notice'>
          <p>Notice: Make sure you are connected to the internet and that the server is online before attempting to join. If you are having trouble connecting to the server, you can try restarting the game or checking the server's status on the MythosCraft website.</p>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import "./teampage.css"

export default function TeamPage() {
  return (
    <div className='mc__teampage'>
    <div className='mc__teampage-info'>
        <h1>CURRENT JOB OPENINGS</h1>
        <div className='mc__teampage-info_bar'></div>
        <i>All staff roles are for ages 16+ unless otherwise specified!</i>
    </div>
    <div className='mc__teampage-join'>
        <h1>Developers</h1>
        <div className='mc__teampage-join_grey'>
            <div className='mc__teampage-join_grey_info'>
                <h2>Plugin Developer</h2>
                <p>Paid</p>
            </div>
            <div className='mc__teampage-join_grey_apply'>
                <a href="mailto:info@mythosmc.com?subject=MythosCraft Developer Application">Apply</a>
            </div>
        </div>
        <div className='mc__teampage-join_white'>
            <div className='mc__teampage-join_white_info'>
                <h2>Resource Pack Developer</h2>
                <p>Paid</p>
            </div>
            <div className='mc__teampage-join_white_apply'>
                <a href="mailto:info@mythosmc.com?subject=MythosCraft Resource Pack Developer Application">Apply</a> 
            </div>
        </div>
        <div className='mc__teampage-join_grey'>
            <div className='mc__teampage-join_grey_info'>
                <h2>Game Designer</h2>
                <p>Volunteer</p>
            </div>
            <div className='mc__teampage-join_grey_apply'>
                <a href="mailto:info@mythosmc.com?subject=MythosCraft Game Designer Application">Apply</a> 
            </div>
        </div>
        <h1>Moderation</h1>
        <div className='mc__teampage-join_grey'>
            <div className='mc__teampage-join_grey_info'>
                <h2>Administrator</h2>
                <p>Paid - 18+</p>
            </div>
            <div className='mc__teampage-join_grey_apply'>
                <a href="mailto:info@mythosmc.com?subject=MythosCraft Administrator Application">Apply</a> 
            </div>
        </div>
        <div className='mc__teampage-join_white'>
            <div className='mc__teampage-join_white_info'>
                <h2> Discord Moderator</h2>
                <p>Volunteer</p>
            </div>
            <div className='mc__teampage-join_white_apply'>
                <a href='https://forms.gle/bWsFmrMeFqscwgmW7'>Apply</a>
            </div>
        </div>
        <div className='mc__teampage-join_grey'>
            <div className='mc__teampage-join_grey_info'>
                <h2>Minecraft Moderator</h2>
                <p>Volunteer</p>
            </div>
            <div className='mc__teampage-join_grey_apply'>
                <a href='https://forms.gle/bWsFmrMeFqscwgmW7'>Apply</a>
            </div>
        </div>
        <h1>Build Team</h1>
        <div className='mc__teampage-join_grey'>
            <div className='mc__teampage-join_grey_info'>
                <h2>Builder</h2>
                <p>Volunteer - 14+</p>
            </div>
            <div className='mc__teampage-join_grey_apply'>
                <a href='https://forms.gle/iSAipLvD7Kb8SCMq8'>Apply</a>
            </div>
        </div>
        <h1>Partnership</h1>
        <div className='mc__teampage-join_grey'>
            <div className='mc__teampage-join_grey_info'>
                <h2>Server Partner</h2>
                <p>N/A</p>
            </div>
            <div className='mc__teampage-join_grey_apply'>
                <a href='https://forms.gle/WxA2WKx2s5abX4yQ8'>Apply</a>
            </div>
        </div>
    </div>


</div>
  )
}

import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, Apply, Play, Community, ErrorPage } from './pages'
import { ScrollToTop } from "./setup"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/play" element={<Play />} />
              <Route path="/community" element={<Community />} />
              <Route path="/apply" element={<Apply />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react'
import { Navbar, Footer } from "../../components"
import { Header, Play, Team, Community } from "../Home"

export default function Home() {

  return (
    <div className='mc__home'>
        <Navbar />
        <Header />
        <Play />
        <Community />
        <Team />
        <Footer />
    </div>
  )
}

import React from 'react'
import "./commteam.css"
import finnskin from "../../../assets/images/finn2.png"
import tobble from "../../../assets/images/tobble.png"

export default function CommTeam() {
  return (
  <div className='mc__commteam' id="team">
    <div className='mc__commteam-container'>
        <h1>Meet the Bots Behind MythosCraft!</h1>
        <div className='mc__commteam-container_content'>
          <div className='mc__commteam-container_content-left'>
            <img src={tobble} alt='tobble'/>
          </div>
          <div className='mc__commteam-container_content-text'>
            <p>TobbleCobble and OutragedFinn started MythosCraft in 2019 after playing on a star wars themed server and thinking it was a cool idea that we could implement in a better way in order to bring the Greek Mythos to Minecraft.</p>
            <p>Along with a brilliant mod and dev team, MythosCraft truly brings to life Greek Mythology in Minecraft!</p>
          </div>
          <div className='mc__commteam-container_content-right'>
            <img src={finnskin} alt='outragedfinn'/>
          </div>
        </div>
        <div className='mc__commteam-container-img'>
          <div className='mc__commteam-container-img_tobble'><img src={tobble} alt='tobble'/></div>
          <div><img src={finnskin} alt='outragedfinn'/></div>
        </div>
    </div>
  </div>
  )
}

import React, { useState } from 'react'
import { RiArrowRightUpLine, RiFileCopyLine, RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from "../../assets/brand/logo.png"
import discord from '../../assets/images/discord.png'
import copy from "copy-to-clipboard"; 
import "./navbar.css"

export default function Navbar() {
  
  // function Online() {
  //   const [data, setData] = useState(null);
  //   const [loading, setLoading] = useState(true);
  //   const [error, setError] = useState(null);
  
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch('https://mcapi.us/server/status?ip=play.mythosmc.com');
  //         const data = await response.json();
  //         setData(data);
  //         setLoading(false);
  //       } catch (error) {
  //         setError(error);
  //         setLoading(false);
  //       }
  //     };
  //     fetchData();
  //   }, []);
  
  //   if (loading) {
  //     return <p>Loading...</p>;
  //   }
  //   if (error) {
  //     return <p>An error occurred: {error.message}</p>;
  //   }
  //   if (data) {
  //     return (
  //       <>
  //         {data.online ? <div><p>Status: Online</p><RiIndeterminateCircleFill color={"#58cc02"}/></div> : <div><p>Status: Offline</p><RiIndeterminateCircleFill color={"red"}/></div>}
  //       </>
  //     );
  //   }
  //   return null;
  // }

  const Menu = () => (
    <>
      <div>
        <a href="../">HOME</a>
      </div>
      <div>
        <a href="../play/">PLAY</a>
      </div>
      <div>
        <a href="../community/">COMMUNITY</a>
      </div>
      <div>
        <a href="https://store.mythosmc.com" target="_blank" rel="noreferrer">STORE <RiArrowRightUpLine /></a>
      </div>
      <div>
        <a href="https://mythoscraftmc.fandom.com/wiki/MythosCraft_Wiki" target="_blank" rel="noreferrer">WIKI <RiArrowRightUpLine /></a>
      </div>
    </>
  )

  const copyToClipboard = () => {
    copy("play.mythosmc.com");
    alert(`You have copied the ip: play.mythosmc.com`);
  }

  const [toggleMenu, setToggleMenu] = useState(false);


  return (
    <div className='mc__navbar'>
      <div className='mc__navbar-heading'>
        <a href='../'><img src={logo} alt='logo'/></a>
      </div>
      <div className='mc__navbar-links'>
        <Menu />
      </div>
      <div className='mc__navbar-right'>
        <div className='mc__navbar-right_play'>
          <button onClick={copyToClipboard}>PLAY.MYTHOSMC.COM <RiFileCopyLine color='white' /></button>
        </div>
        <div className='mc__navbar-right_discord'>
          <a href='https://discord.com/invite/FgurDkv74K' target="_blank" rel="noreferrer"><img src={discord} alt='discord'/></a>
        </div>
        <div className="mc__navbar-right_menu">
        {toggleMenu 
                ? <RiCloseLine color='white' size={27} onClick={() => setToggleMenu(false)}/>
                : <RiMenu3Line color='white' size={27} onClick={() => setToggleMenu(true) }/>
              }
        {toggleMenu && (
          <div className='mc__navbar-right_menu-container scale-up-top'>
            <Menu />
          </div>
        )}
      </div>
      </div>
    </div>
  )
}

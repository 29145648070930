import React from 'react'
import "./commbar.css"

export default function CommBar() {
  return (
    <div className='mc__commbar'>
      <a href='#community'>Community</a>
      <a href='#team'>Our Team</a>
      <a href='#videos'>Videos</a>
    </div>
  )
}

import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./community.css"
import hades from "../../../assets/images/olympus.png"

export default function Community() {
  const navigate = useNavigate()
  return (
    <div className='mc__community'>
      <div className='mc__community-container'>
        <div className='mc__community-container_img2'>
          <img src={hades} alt='community'></img>
        </div>         
        <div className='mc__community-container_content'>
          <h1>Join a thriving community</h1>
          <p>Our active playerbase and community of 10,000+ members means you can always find someone to duel, quest with or adventure through the greek mythos with!</p>
          <button onClick={()=>navigate("/community")}>Community News</button>
        </div>
        <div className='mc__community-container_img'>
          <img src={hades} alt='community'></img>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import "./commvideo.css"
export default function CommVideo(props) {

  const vids = props.video.link.slice(32)

  return (
    <div className="mc__commvideo">
      <div className='mc__commvideo-container'>
        <div className="mc__commvideo-container_image">
                {/* <img src={`https://i4.ytimg.com/vi/${props.video.guid.split(':')[2]}/mqdefault.jpg`} /> */}
        <iframe src={`https://www.youtube.com/embed/${vids}`}></iframe>
        </div>
        {/* <h1 className="mc__commvideo-container_title">{props.video.title}</h1> */}
      </div>
    </div>
  )
}

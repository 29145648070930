import React from 'react'
import testskin from "../../../assets/images/ares2.png"
import "./commdiscord.css"

export default function CommDiscord() {
  return (
    <div className='mc__commdiscord' id='community'>
      <div className='mc__commdiscord-container'>
        <div>
          <div className='mc__commdiscord-container_heading'>
            <h1>Join Our Discord Community</h1>
            <img src={testskin} alt="gladiator"/>
          </div>
          <p>Interested in becoming an active community member?</p>
          <p>Our discord server is a good place to go to stay up to date, hang out and play with others, with all things MythosCraft related</p>
          <p>We currently have 10 000+ members and hope to see you there!</p>
          <a href='https://discord.com/invite/FgurDkv74K' target="_blank" rel="noreferrer"><button>Join our Discord!</button></a>
        </div>
        <div className='mc__commdiscord-container_img'>
          <img src={testskin} alt="gladiator"/>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import "./team.css"
import olympus from "../../../assets/images/olympus1.png"
import { useNavigate } from 'react-router-dom'

export default function Team() {
  const navigate = useNavigate()
  return (
    <div className='mc__team'>
      <div className='mc__team-container'>
        <div className='mc__team-container_img'>
          <img src={olympus} alt='olympus' ></img>
        </div>
        <div className='mc__team-container_content'>
            <h1>Our Goal: Total Server Domination</h1>
            <p>The team at MythosCraft is dedicated to creating new gods, events, custom mobs and gamemodes and much more!</p>
            <p>Our team includes plugin developers, moderators and administration and social media managers who all work together to provide the best player experience possible while staying true to Greek Mythology.</p>
            <p> Think you have what it takes?</p>
            <button onClick={()=>navigate("/apply")}>Apply Now!</button>
        </div>
      </div>
    </div>
  )
}
import React from 'react'
import { Navbar, Footer } from "../../components"
import { CommBar, CommContent, CommDiscord, CommHeader, CommTeam, CommVideo } from "../Community"
export default function Community() {
  return (
    <div className='mc__Community'>
      <Navbar />
      <CommHeader />
      <CommBar />
      <CommContent />
      <CommDiscord />
      <CommTeam />
      <Footer />
    </div>
  )
}

import React from 'react'
import "./commheader.css"

export default function CommHeader() {
  return (
    <div className='mc__commheader'>
      <div className='mc__commheader-container'>
        <h1>WELCOME TO THE MYTHOSCRAFT COMMUNITY</h1>
        {/* <p>Check out all community related content and more going on at MythosCraft!</p> */}
      </div>
    </div>
  )
}
